<template>
    <BaseArticle :img="require('@/assets/blog/article2.png')"
                 @open="$emit('open')"
                 @back="$emit('back')"
                 :subject="subject"
                 :date="date"
                 :author="author"
                 :abstract="abstract"
                 :asThumb="asThumb">
        <p>很多创业者在美国成立公司，公司注册结束后的第一件事就是想要开设公司银行账户，尽快开始公司业务的运营。有了公司的银行账户之后，不仅能够开始以公司名义进行收支，并且能够通过将业务资金与公司负责人的个人资金分开来提供有限责任保护。可以授权员工代表处理日常业务，方便使用。<br />
            所以说在美开展业务，有一个公司银行账户势在必行。拥有公司账户，公司业务就可以立刻步入快速、正轨发展的轨道。</p>

        <p>那么在美国开设公司银行账户到底需要哪些材料？如何选择一个较好的公司账户呢？公司负责人不在美国能不能申请公司银行账号呢？<br />
            这篇文章就干货满满，手把手教你如何在美国开设公司银行账户，让你先行一步！</p>

        <p>需要材料：<br />
            1. 公司注册证书<br />
            2. 联邦税号（EIN）<br />
            3. 公司章程和公司经营范围<br />
            4. 公司负责人身份证和护照扫描件<br />
            5. 开户人（公司负责人）电话、邮箱、母亲姓名<br />
            6. 一定的账户预存款（各银行要求不同）</p>

        <p>开户需要的时间：<br />
            2-3周（具体以各银行时间为准）</p>

        <p>如何选择一个较好的公司账号？<br />
            选择公司账户时可以注意以下事项：<br />
            1. 折扣率：处理的每笔交易的收费百分比<br />
            2. 地址验证服务（AVS）费用<br />
            3. ACH每日批量费用：当您结算今天的信用卡交易时收取的费用<br />
            4. 每月最低费用：如果您的业务不符合最低要求的交易费用<br />
            - <br />
            如果你由于各种原因无法亲自来到美国开设公司银行账户，也无需担心。在我们的帮助下可以省去许多麻烦，可以轻松远程开设公司银行账户。详情可以与我们联系获取更多信息。<br />
        </p>
    </BaseArticle>
</template>

<script>
  import BaseArticle from '../Block/BaseArticle'

  export default {
    name: "Article1",
    components: {BaseArticle},
    props: ['asThumb'],
    data() {
      return {
        subject: "在美国如何开设公司银行账号",
        date: "5/15/2019",
        author: "Yuki Zhang",
        abstract: "在美国开设公司银行账号到底需要哪些材料？如何选择一个较好的公司账号呢？" +
          "公司负责人不在美国能不能申请公司银行账号呢？" +
          "这篇文章就干货满满，手把手教你如何在美国开设公司银行账号，让你先行一步！"
      }
    }
  }
</script>

<style scoped>

</style>
